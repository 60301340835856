import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "error", "entries", "button", "thanks" ]

  submit() {
    if ($(this.inputTargets).filter(":checked").val() == undefined) {
      $(this.errorTarget).removeClass("hidden")
      return;
    }

    $(this.entriesTarget).addClass("hidden")
    $(this.buttonTarget).addClass("hidden")

    var $thanksTarget = $(this.thanksTarget)

    $.post("/survey", {answer: $(this.inputTargets).filter(":checked").val()}, function() {
      $thanksTarget.removeClass("hidden")
    })
  }
}
