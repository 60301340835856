import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "globe", "select" ]

  show(e) {
    this.globeTarget.classList.add("hidden")
    this.selectTarget.classList.remove("hidden")
  }

  redirect(e) {
    if (this.selectTarget.value != "") {
      window.location.href = this.selectTarget.value
    }
  }
}
