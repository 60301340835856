import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  hide(e) {
    if (e.key == "Escape") {
      $(this.formTargets).addClass("xl:hidden")
    }
  }

  show() {
    var $formTargets = $(this.formTargets)
    $formTargets.removeClass("xl:hidden")
    $formTargets.find("input[type=\"text\"]").focus().focusout(function() {
      $formTargets.addClass("xl:hidden")
    })
  }
}
