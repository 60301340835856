import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "open", "close", "items" ]

  toggle(e) {
    if ($(this.openTarget).hasClass("hidden")) {
      $(this.openTarget).removeClass("hidden")
      $(this.closeTarget).addClass("hidden")
      $(this.itemsTarget).addClass("hidden")
    } else {
      $(this.openTarget).addClass("hidden")
      $(this.closeTarget).removeClass("hidden")
      $(this.itemsTarget).removeClass("hidden")
    }
  }
}
